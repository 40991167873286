import * as React from "react"

function IconBatch(props) {
    return (
        <svg className="icon-size" width="24" height="24" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3701_16648)">
                <path d="M8.00022 9.80271C9.82224 9.80271 11.2993 8.46197 11.2993 6.80809C11.2993 5.15421 9.82224 3.81348 8.00022 3.81348C6.17821 3.81348 4.70117 5.15421 4.70117 6.80809C4.70117 8.46197 6.17821 9.80271 8.00022 9.80271Z" stroke="#828295" strokeMiterlimit="10" />
                <path d="M7.99991 13.0772C11.8143 13.0772 14.9066 10.2703 14.9066 6.80787C14.9066 3.34544 11.8143 0.538574 7.99991 0.538574C4.18547 0.538574 1.09326 3.34544 1.09326 6.80787C1.09326 10.2703 4.18547 13.0772 7.99991 13.0772Z" stroke="#828295" strokeMiterlimit="10" />
                <path d="M11.2993 12.3232V17.2245L8.00022 16.1473L4.70117 17.2245V12.3232" stroke="#828295" strokeMiterlimit="10" />
            </g>
            <defs>
                <clipPath id="clip0_3701_16648">
                    <rect width="15" height="18" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconBatch

import React from "react";
import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { SettingsContext } from "../settingsContext";

function StandardTooltip(props) {
    const { t, type, description } = props;

    const settings = React.useContext(SettingsContext);

    return (<ReactTooltip id={"t-" + type} type='dark' effect="solid" afterHide={() => { document.activeElement.blur(); }} clickable={true} scrollHide={true} delayHide={1000} className={settings.is_mobile ? "smx-3" : "smx-8"}>
        {settings.is_mobile && <div className="d-flex flex-row-reverse">
            <button
                className="c-pointer btn btn-link text-white text-decoration-none p-0"
                onClick={(event) => {
                    event.target.parentNode.parentNode.classList.remove('show')
                    ReactTooltip.hide()
                }}>x</button>
        </div>}
        <p className="text-white a-white" dangerouslySetInnerHTML={{
            __html: t(description),
        }}>
        </p>
    </ReactTooltip>);
}

export default withTranslation()(StandardTooltip);
import React from "react";
import { withTranslation } from "react-i18next";

import { SettingsContext } from "../settingsContext";
import {
    IconExrrorExclamatory
} from "../icons";

function StandardTooltipIcon(props) {
    const { type } = props;

    const settings = React.useContext(SettingsContext);

    return (<IconExrrorExclamatory
        className="c-pointer"
        data-tip
        data-for={"t-" + type}
        data-event={settings.is_mobile ? 'focus' : ''}
        fill={"#cd4400"}
        onClick={(e) => { e.preventDefault(); e.stopPropagation(); return false; }} />);
}

export default withTranslation()(StandardTooltipIcon);
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { withTranslation } from "react-i18next";

import "./scss/Root.scss";

import { SettingsContext } from "./components/settingsContext";
import { SetDefault } from "./components/loading";
import { ErrorBoundary } from "./components/errorBoundary";

function App(props) {
  const { t, i18n } = props;

  const rootEl = document.getElementById("r-a");

  //Add direction attribute
  rootEl.dir = i18n.dir();

  SetDefault({ title: t("PageLoading"), text: t("LoadingContent") });

  const settings = React.useContext(SettingsContext);

  //TODO: Having issue with lazy loading using the CDN
  const Search = React.lazy(() => import("./components/search"));
  const ProductDetails = React.lazy(() => import("./components/productDetails"));

  return (
    <Router>
      <div className="psa">
        <ErrorBoundary>
          <Switch>
            <Route
              exact={true}
              path={settings.searchpage_url}
              render={(props) => <Search {...props} />}
            />
            <Route
              path={settings.productdetails_url + "/:id"}
              render={(props) => <ProductDetails {...props} />}
            />
          </Switch>
        </ErrorBoundary>
      </div>
    </Router>
  );
}

export default withTranslation()(App);

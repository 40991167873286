import * as React from "react"

function IconLabel(props) {
    return (
        <svg
            width={25}
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="m23.214 16.046-6.668 6.668a1.286 1.286 0 0 1-1.817 0l-11.16-11.16a.617.617 0 0 1-.189-.497l1.011-6.583a.634.634 0 0 1 .583-.583l6.583-1.011a.618.618 0 0 1 .497.189l11.16 11.16a1.285 1.285 0 0 1 0 1.817v0ZM7.409 6.909l-6-6"
                stroke="#333"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default IconLabel

import * as React from "react"

function IconSearch(props) {
    return (
        <svg
    width={23}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.892 17.869a7.934 7.934 0 1 0 0-15.87 7.934 7.934 0 0 0 0 15.87ZM21 21.043l-5.49-5.49"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
    )
}

export default IconSearch

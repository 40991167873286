import * as React from "react"

function IconPhone(props) {
    return (

        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            d="M15.017 22.286a5.468 5.468 0 0 0 6.857-.755l.772-.754a1.851 1.851 0 0 0 0-2.588l-3.275-3.24a1.834 1.834 0 0 0-2.571 0v0a1.851 1.851 0 0 1-2.589 0L9.07 9.806a1.817 1.817 0 0 1 0-2.589v0a1.834 1.834 0 0 0 0-2.571L5.81 1.389a1.851 1.851 0 0 0-2.588 0l-.754.771a5.469 5.469 0 0 0-.755 6.857 49.61 49.61 0 0 0 13.303 13.269v0Z"
            stroke="#007367"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </svg>
    )
}

export default IconPhone

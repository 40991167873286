import * as React from "react"

function IconExrrorExclamatory(props) {
    let fill = props.fill ?? '#e64c00';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={16}
            viewBox="0 0 32 32"
            width={16}
            overflow="visible"
            {...props}
        >
            <circle cx={16} cy={16} r={16} fill={fill} />
            <path d="M14.5 25h3v-3h-3v3zm0-19v13h3V6h-3z" fill="#ffffff" />
        </svg>
    )
}

export default IconExrrorExclamatory
import * as React from "react"

function IconDocument(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            fill="none"
            {...props}
        >
            <path
                d="M12 23.143a15.874 15.874 0 0 0-9.617-5.057A1.714 1.714 0 0 1 .857 16.37v-13.8A1.714 1.714 0 0 1 2.811.874 15.943 15.943 0 0 1 12 5.88v17.263ZM12 23.143a15.874 15.874 0 0 1 9.617-5.057 1.714 1.714 0 0 0 1.526-1.715v-13.8A1.716 1.716 0 0 0 21.189.874 15.943 15.943 0 0 0 12 5.88v17.263Z"
                stroke="#333"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default IconDocument

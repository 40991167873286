import React from "react";

const SearchContext = React.createContext({});

export const SearchProvider = (props) => {
    return (
        <SearchContext.Provider value={props.value}>
            {props.children}
        </SearchContext.Provider>
    );
}
export default SearchContext;
import * as React from "react"

const IconArrowRight = (props) => (
  <svg
    width={25}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m8.75 18 7.5-5-7.5-5"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
)

export default IconArrowRight
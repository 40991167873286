import { IconCrop, IconPest } from "../icons";
import { allCropConstant } from "../../services/utilities";

export function treeNodeDetails(node, nodeType, t) {
    //For all crop, we needs different value to display
    const getNodeDisplayName = (type, displayName) => {
        return type === "C" && displayName && displayName.toLowerCase() === allCropConstant.name
            ? t(allCropConstant.translation)
            : displayName;
    };

    return (
        <span className="node">
            {nodeType === "C" ? <IconCrop /> : <IconPest />}{" "}
            <span className="name">
                {getNodeDisplayName(nodeType, node.displayName)}
            </span>
        </span>
    );
}

import React from "react";

const SettingsContext = React.createContext({});

export const SettingsProvider = (props) => {
    return (
        <SettingsContext.Provider value={props.value}>
            {props.children}
        </SettingsContext.Provider>
    );
}
export default SettingsContext;

import * as React from "react"

function IconEmail(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <g
        clipPath="url(#a)"
        stroke="#007367"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M.857 3h22.286v18H.857V3Z" />
        <path d="m.857 5.169 10.037 8.47a1.715 1.715 0 0 0 2.212 0l10.037-8.47" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconEmail

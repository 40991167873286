import React from "react";
import { withTranslation, useTranslation } from "react-i18next";

import { SettingsContext } from "../settingsContext";
import { searchApplication } from "../../services/utilities";

function CategoryLink(props) {
  const { name, nameEN, t } = props;

  const { i18n } = useTranslation("translations");

  const settings = React.useContext(SettingsContext);

  let displayName = searchApplication.getName({name: name, nameEN: nameEN});

  //RemoveSpace special characters and spaces
  let categoryKey = searchApplication.removeSpecialCharacters(nameEN);

  let linkKey = `${categoryKey}_Link`;

  let handleClick = (event) => {
    if (settings.standalone && !navigator.onLine) {
      event.preventDefault(); // Prevent the default link behavior
      alert(t("Offlinedownload"));
    }
  };

  if (i18n.exists(linkKey)) {
    return (
      <a
        className="text-decoration-none fw-bold"
        href={t(linkKey)}
        onClick={handleClick}
        target="_blank"
        rel="noreferrer"
      >
        {displayName}
      </a>
    );
  } else {
    return <span>{ displayName }</span>;
  }
}

export default withTranslation()(CategoryLink);

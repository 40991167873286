import React from "react";
import { withTranslation, useTranslation, Trans } from "react-i18next";

import { SettingsContext } from "../settingsContext";
import { searchApplication } from "../../services/utilities";

function CategorySection(props) {
  const { nameEN, t } = props;

  const settings = React.useContext(SettingsContext);

  //RemoveSpace special characters and spaces
  let categoryKey = searchApplication.removeSpecialCharacters(nameEN);
  let linkKey = `${categoryKey}_Link`;
  let imageKey = `${categoryKey}_Image`;
  let titleKey = `${categoryKey}_Title`;
  let descriptionKey = `${categoryKey}_Description`;

  const { i18n } = useTranslation("translations");

  let handleClick = (event) => {
    if (settings.standalone && !navigator.onLine) {
      event.preventDefault(); // Prevent the default link behavior
      alert(t('Offlinedownload'));
    }
  }

  if (i18n.exists(linkKey)) {
    return (
      <div className="row" data-category={nameEN}>
        <div className={settings.is_mobile ? "col-12" : "col-12 py-5"}>
          <div className="row">
            {!settings.is_mobile && t(imageKey) && (
              <div className="col-2">
                <img className="w-100" alt="" src={t(imageKey)} />
              </div>
            )}
            <div className={settings.is_mobile ? "col-12" : "col-10"}>
              <h3 className="py-3">{t(titleKey)}</h3>
              <p>{t(descriptionKey)}</p>
              <p>
                <a
                  className="btn p-info-btn me-3"
                  href={t(linkKey)}
                  target="_blank"
                  rel="noreferrer"
                  onClick={handleClick}
                >
                  <Trans>Category_ReadMore</Trans>
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="line-break"></div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default withTranslation()(CategorySection);

import React from "react";
import { useEffect, useState } from "react";
import { withTranslation, Trans } from "react-i18next";

import { searchApplication } from "../../services/utilities";

/**
 * Component to render available languages
 * @param {Array} props
 * @returns render available languages html
 */
export function AvailableInLanguage(props) {
    const { languages } = props;

    const [appState, setAppState] = useState({
        show: false,
        message: "",
    });

    useEffect(() => {
        if (
            languages !== null &&
            Array.isArray(languages) &&
            languages.length > 0
        ) {
            var msg = "";
            var i = 1;
            if (languages.length === 1) {
                msg = `ProductInformation${languages[0].nameEn}`;
            } else {
                let clonedLanguages = searchApplication.englishLanguageAtEnd(languages);

                if (clonedLanguages.length === 2) {
                    msg = `ProductInformation${clonedLanguages[0].nameEn}${clonedLanguages[1].nameEn}`;
                } else {
                    msg = `ProductInformation${clonedLanguages[0].nameEn}`;
                    for (i = 1; i < clonedLanguages.length - 1; i++) {
                        msg += `${clonedLanguages[i].nameEn}`;
                    }
                    msg += `${clonedLanguages[clonedLanguages.length - 1].nameEn}`;
                }
            }

            setAppState({
                show: true,
                message: msg,
            });
        }
    }, [setAppState, languages]);

    return (
        <>
            {appState.show ? (
                <div className="info mt-3">
                    <span data-testid="available-in-language">
                        <Trans>{appState.message}</Trans>
                    </span>
                </div>
            ) : null}
        </>
    );
}

export default withTranslation()(AvailableInLanguage);

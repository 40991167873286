import React from "react";
import { components } from "react-select";
import { withTranslation, Trans } from "react-i18next";

/**
 * Component to render no option for react-select
 * @param {*} props
 * @returns
 */
const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
          <span className="no-option"><Trans>Nooptions</Trans></span>
    </components.NoOptionsMessage>
  );
};

export default withTranslation()(NoOptionsMessage);

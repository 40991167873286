import * as React from "react"

function IconArrowLeft(props) {
  return (
    <svg
    width={10}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.5 1 1 6l7.5 5"
      stroke="#368729"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
  )
}

export default IconArrowLeft
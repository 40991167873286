import * as React from "react"
const IconBell = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <path
            d="M12.047 5.648c.332 0 1.121.207 1.121.207 2.137.493 3.64 2.461 3.64 4.684v5.98l.438.438.363.367H6.391l.363-.367.437-.437v-5.98c0-2.224 1.504-4.192 3.641-4.685 0 0 .848-.207 1.121-.207M12 2.25c-.824 0-1.457.633-1.457 1.46v.685c-2.766.632-4.852 3.168-4.852 6.144v5.36L3.75 17.851v.972h16.5v-.972l-1.941-1.954V10.54c0-2.976-2.086-5.512-4.852-6.144V3.71c0-.828-.633-1.461-1.457-1.461Zm1.941 17.55H10.06c0 1.071.875 1.95 1.941 1.95a1.953 1.953 0 0 0 1.941-1.95Zm0 0"
            style={{
                stroke: "none",
                fillRule: "nonzero",
                fill: "#888",
                fillOpacity: 1,
            }}
        />
    </svg>
)
export default IconBell
import TagManager from "react-gtm-module";
import { Loading, LoadingHide } from "../components/loading";
import axios from "axios";
import i18n from "../i18n";

/**
 * URL query parameter names
 */
export const queryParamName = {
    country: "country",
    lng: "lng",
    id: "id",
    occupation: "occupation",
    crop: "crop",
    pest: "pest",
    filter: "filter",
    activeingredient: "activeingredient",
    order: "order",
    supportedState: "supportedState",
    state: "state",
    pageNo: "pageNo",
    supportedStates: "supportedStates",
};

/**
 * Product info links names
 */
const productInfoLinksName = {
    productLabel: "Product Label",
    productLabelEN: "Product Label EN",
    manufacturerSafetyDatasheet: "Manufacturer Safety Datasheet",
    manufacturerSafetyDatasheetEN: "Manufacturer Safety Datasheet EN",
    productFactsheet: "Product Factsheet",
    productFactsheetEN: "Product Factsheet EN",
};

/**
 * Constant for all crops
 */
export const allCropConstant = {
    name: "all",
    translation: "PermittedForAllCrop",
};

/**
 * Method to interpolate a string having placeholder {{}}
 * @param {string} s
 * @param {object} o
 * @returns interpolated string
 */
export const stringInterpolator = function (s, o) {
    return s.replace(/{{([^{}]*)}}/g, function (a, b) {
        var r = o[b];
        return typeof r === "string" || typeof r === "number"
            ? r
            : r === null
                ? ""
                : a;
    });
};

export const consoleLogger = function (value) {
    if (consoleLogger.enabled && console && console.log) console.log(...value);
};

/**
 * Helper methods for the application
 */
export const searchApplication = {
    /**
     * Remove special characters and spaces from string
     */
    removeSpecialCharacters: function (value) {
        return value.replace(/[^a-zA-Z0-9]/g, "").replace(/\s/g, "");
    },

    getDocLng: function () {
        let doclng = document.documentElement.lang ?? "en";
        if (doclng === "en-US") {
            doclng = "en";
        }

        return doclng.toLowerCase();
    },

    getQueryLng: function () {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get("lng")?.toLocaleLowerCase();
    },

    /**
     * Check for Crawlers
     */
    isCrawler: function () {
        const userAgent = window.navigator.userAgent;
        return (/googlebot|bingbot|yandex|baiduspider|prerender|prerendered\.io/i.test(
            userAgent
        ));
    },

    /**
     * Method to sort crop or pest lsit
     * @param {Array} List of crop/pest
     * @param {String} nodeType crop/pest
     * @returns sorted array
     */
    sortCropPest: function (list, nodeType) {
        if (list && list.length > 0) {
            if (nodeType === "C") {
                let sorted = list.sort(this.sortOnAsc("displayName"));

                let allCrop = sorted.filter(
                    (s) => s.displayName.toLowerCase() === allCropConstant.name
                );
                if (allCrop.length === 1) {
                    let withoutAll = sorted.filter(
                        (s) => s.displayName.toLowerCase() !== allCropConstant.name
                    );

                    withoutAll.unshift(allCrop[0]);

                    return withoutAll;
                }

                return sorted;
            } else {
                return list.sort(this.sortOnAsc("displayName"));
            }
        }
        return list;
    },

    regexProductId: function (id) {
        return /(\d+)([^-]*$)/.exec(id);
    },

    createURLComponentSlug: function (component) {
        return encodeURIComponent(
            component
                .replace(/[`~!@#$%^&*()_\-+=[\]{};:'"\\|/,.<>?\s]/g, " ")
                .replace(/^\s+|\s+$/gm, "")
                .replace(/-/g, " ")
                .replace(/  +/g, " ")
                .replace(/ /g, "-")
        ).toLowerCase();
    },

    /**
     * Create product category name replacing space and special character with _
     * @param {*} name
     */
    createCategoryIconName: function (name) {
        if(name)
            return (name.replace(/[^A-Z0-9]/gi, "_") + ".svg").toLowerCase();
        else
            return '';
    },

    /**
     * Sort an array in random order
     * @param {any} array
     */
    sortRandom: function shuffle(array) {
        let currentIndex = array.length,
            randomIndex;

        // While there remain elements to shuffle...
        while (currentIndex !== 0) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex],
                array[currentIndex],
            ];
        }

        return array;
    },
    /**
     * Initlialize the application
     * @param {*} settings
     * @returns add additional setting
     */
    init: (settings) => {
        /**
         * Initialize i18n
         */
        const detectionOptions = {
            // order and from where user language should be detected
            order: settings.standalone ? ["htmlTag"] : ["querystring", "htmlTag"],

            // don't cache user language
            caches: false,
        };

        const backendOptions = {
            loadPath:
                `${settings.api_url}/${settings.translation_endpoint}` ??
                "locales/{{lng}}/{{ns}}.json",
            crossDomain: true,
        };

        let lng = settings.getlng();

        if (settings.hasStaticContent === "true" && lng === window.datalng) {
            let option = {
                lng: lng,
                debug: false,
                resources: {},
            };

            option.resources[lng] = {
                translation: window.translation,
            };

            i18n.init(option);
        } else {
            i18n.init({
                fallbackLng: false,
                debug: false,
                saveMissing: false,
                ns: ["translations"],
                defaultNS: "translations",
                keySeparator: ".",
                interpolation: {
                    escapeValue: false,
                    formatSeparator: ",",
                },
                detection: detectionOptions,
                backend: backendOptions,
                react: {
                    useSuspense: true,
                },
            });
        }

        let pattern = new RegExp("^GTM-[a-zA-Z0-9]{1,7}$");
        let isGTMEnabled = pattern.test(settings.gtm_id ?? "");

        if (isGTMEnabled) {
            const tagManagerArgs = {
                gtmId: settings.gtm_id,
                dataLayerName: "BioprotectionPortal",
            };
            TagManager.initialize(tagManagerArgs);
        }

        /**
         * Initialize axios
         */
        axios.interceptors.request.use(
            function (successfulReq) {
                if (!successfulReq.notShowProgress) {
                    Loading();
                }
                return successfulReq;
            },
            function (error) {
                return Promise.reject(error);
            }
        );

        axios.interceptors.response.use(
            function (successRes) {
                LoadingHide();
                return successRes;
            },
            function (error) {
                LoadingHide();
                return Promise.reject(error);
            }
        );

        return { ...settings, isGTMEnabled: isGTMEnabled };
    },

    /**
     * Method to split crop/pest array into chuncks
     * @param {Number} no of chuncks to be split
     * @param {Array} List of crop/pest
     * @returns splitted array
     */
    splitTree: function (col, datasource) {
        var splitChuncks = [];

        if (datasource.length > 10) {
            let size = Math.floor(datasource.length / col);
            let mod = datasource.length % col;
            let i,
                j,
                temporary,
                chunk = size;
            for (i = 0, j = datasource.length; i < j;) {
                temporary = datasource.slice(i, i === 0 ? i + chunk + mod : i + chunk);
                splitChuncks.push(temporary);

                if (i === 0) i += chunk + mod;
                else i += chunk;
            }
        } else {
            splitChuncks.push(datasource);
        }

        return splitChuncks;
    },

    /**
     * Function to replace special character and space from string
     * Allowed characters [A-Z][a-z][0-9]
     * @param {string} str
     * @returns
     */
    replaceSpecialCharacter: function (str) {
        if (str) return str.replace(/[^A-Z0-9]/gi, "");

        return "";
    },

    historyHelper: {
        /**
         * Create back button URL for the product details page
         * @param {string} search
         */
        productBackUrl: function (searchState, country, includelng = false, stateCode = '') {
            let lng =
                searchState && searchState.language ? searchState.language.value : "";
            let crop = searchState && searchState.crop ? searchState.crop.value : "";
            let pest = searchState && searchState.pest ? searchState.pest.value : "";
            let activeingredient =
                searchState && searchState.activeingredient
                    ? searchState.activeingredient.value
                    : "";
            let supportedState =
                searchState &&
                    searchState.supportedState &&
                    searchState.supportedState.length > 0
                    ? searchState.supportedState
                        .map(function (item) {
                            return item.value;
                        })
                        .join(",")
                    : "";
            let order =
                searchState && searchState.sortOrder ? searchState.sortOrder.value : "";
            let filter =
                searchState && searchState.filter ? searchState.filter ?? "" : "";

            let occupation =
                searchState && searchState.occupation
                    ? searchState.occupation.value
                    : "";

            return (
                `${queryParamName.country}=${country}` +
                (stateCode !== "" ? `&${queryParamName.state}=${stateCode}` : "") +
                (includelng && lng !== "" ? `&${queryParamName.lng}=${lng}` : "") +
                (occupation !== ""
                    ? `&${queryParamName.occupation}=${occupation}`
                    : "") +
                (crop !== "" ? `&${queryParamName.crop}=${crop}` : "") +
                (pest !== "" ? `&${queryParamName.pest}=${pest}` : "") +
                (filter !== "" ? `&${queryParamName.filter}=${filter}` : "") +
                (activeingredient !== ""
                    ? `&${queryParamName.activeingredient}=${activeingredient}`
                    : "") +
                (supportedState !== ""
                    ? `&${queryParamName.supportedState}=${supportedState}`
                    : "") +
                (order !== "" ? `&${queryParamName.order}=${order}` : "")
            );
        },

        /**
         * Create product details link for the search page
         * @param {any} appState
         * @param {string} id
         */
        productDetailUrl: function productDetailUrl(appState, id) {
            var commonSeparatedStateCode = "";
            if (appState.supportedState) {
                appState.supportedState.forEach(function (state) {
                    if (commonSeparatedStateCode) {
                        commonSeparatedStateCode += ",";
                    }
                    commonSeparatedStateCode += state.value;
                });
            }

            if (appState.sortOrder) {
                return (
                    `${queryParamName.crop}=${appState.crop ? appState.crop.value : ""}` +
                    `&${queryParamName.pest}=${appState.pest ? appState.pest.value : ""
                    }` +
                    `&${queryParamName.filter}=${appState.filter ? appState.filter : ""
                    }` +
                    `&${queryParamName.activeingredient}=${appState.activeIngredient ? appState.activeIngredient.value : ""
                    }` +
                    `&${queryParamName.supportedState}=${commonSeparatedStateCode ? commonSeparatedStateCode : ""
                    }` +
                    `&${queryParamName.order}=${appState.sortOrder ? appState.sortOrder.value : "ASC"
                    }`
                );
            } else {
                return (
                    `${queryParamName.crop}=${appState.crop ? appState.crop.value : ""}` +
                    `&${queryParamName.pest}=${appState.pest ? appState.pest.value : ""
                    }` +
                    `&${queryParamName.filter}=${appState.filter ? appState.filter : ""
                    }` +
                    `&${queryParamName.activeingredient}=${appState.activeIngredient ? appState.activeIngredient.value : ""
                    }` +
                    `&${queryParamName.supportedState}=${commonSeparatedStateCode ? commonSeparatedStateCode : ""
                    }`
                );
            }
        },

        /**
         * Method to update history
         * @param {*} history
         * @param {{ country,lng,id,occupation,crop,pest,filter,activeIngredient,sortOrder }} data
         */
        updateHistory: function (history, data) {
            const urlParams = new URLSearchParams(window.location.search);
            let stateCode;
            if (!(data.onConuntryChange)) // Make sure state param not added in URL when country is changed on SearchResult page
                stateCode = urlParams.get('state');
            let doclng = searchApplication.getDocLng();
            const params = new URLSearchParams();

            if (data.country) params.append(queryParamName.country, data.country);
            if ((data.standalone || data.lng !== doclng) && data.lng)
                params.append(queryParamName.lng, data.lng);
            if (data.id) params.append(queryParamName.id, data.id);
            if (data.occupation)
                params.append(queryParamName.occupation, data.occupation);
            if (data.crop) params.append(queryParamName.crop, data.crop);
            if (data.pest) params.append(queryParamName.pest, data.pest);
            if (data.filter) params.append(queryParamName.filter, data.filter);
            if (data.activeIngredient)
                params.append(queryParamName.activeingredient, data.activeIngredient);
            if (stateCode != null) {
                params.append(queryParamName.state, stateCode);
            }
            if (data?.pageNo) params.append(queryParamName?.pageNo, data.pageNo);
            if (data?.supportedStates) params.append(queryParamName?.supportedStates, data.supportedStates);

            if (data.sortOrder) params.append(queryParamName.order, data.sortOrder);
            history.push({ search: params.toString() });
        },

        /**
         * Create an object from URL
         * @param {string} search
         */
        queryParam: (search) => {
            const trustValue = (value) => {
                return value === "" || value === null ? null : value;
            };
            const cleanCountry = (country) => {
                const httpIndex = country.indexOf('http');
                if (httpIndex !== -1) {
                    return country.slice(0, httpIndex); // Return country code before URL
                }
                return country;
            };

            let occuaption = "";
            if (window.localStorage) {
                occuaption = window.localStorage.getItem(queryParamName.occupation);
            }

            const query = new URLSearchParams(search);

            const state = trustValue(query.get(queryParamName.state));
            const country = cleanCountry(trustValue(query.get(queryParamName.country)));

            var returnValue = {};
            returnValue.search = search;
            returnValue.country = country;
            if (state) {
                returnValue.state = state;
            }
            returnValue.lng = trustValue(query.get(queryParamName.lng)) ?? "en";
            returnValue.id = trustValue(query.get(queryParamName.id));
            returnValue.occupation = trustValue(
                query.get(queryParamName.occupation) ?? occuaption
            );
            returnValue.crop = trustValue(query.get(queryParamName.crop));
            returnValue.pest = trustValue(query.get(queryParamName.pest));
            returnValue.filter = trustValue(query.get(queryParamName.filter));
            returnValue.supportedStates = trustValue(
                query.get(queryParamName.supportedStates)
            );
            returnValue.activeIngredient = trustValue(
                query.get(queryParamName.activeingredient)
            );
            returnValue.sortOrder = trustValue(query.get(queryParamName.order));
            returnValue.pageNo = trustValue(query.get(queryParamName.pageNo));

            return returnValue;
        },
    },

    /**
     * Method to get display name from local and english name of the item
     * @param {{ name, nameEN }} item
     * @returns display name
     */
    getName: (item) => {
        if (item) {
            if (item.name === undefined || item.name === null || item.name === "") {
                if (
                    item.nameEN === undefined ||
                    item.nameEN === null ||
                    item.nameEN === ""
                ) {
                    return null;
                } else {
                    return item.nameEN;
                }
            } else {
                return item.name;
            }
        }
        return null;
    },

    /**
     * Create state for the search
     * @param {any} appState old app state
     * @param {any} language
     * @param {any} occupation
     * @param {any} crop
     * @param {any} pest
     * @param {any} data
     */
    transformInitialData: function (
        appState,
        language,
        occupation,
        crop,
        pest,
        data,
        t,
        currentPage = 1
    ) {
        let self = this;

        let doclng = searchApplication.getDocLng();

        var selectedLanguage = null;
        var selectedOccupation = null;
        var selectedCrop = null;
        var selectedPest = null;

        const result = {
            activeIngredients: data?.activeIngredients ?? [],
            productsList: data?.products ?? []
        }

        if (
            data !== null &&
            data.languages !== null &&
            Array.isArray(data.languages) &&
            data.languages.length > 0
        ) {
            var sellng = data.languages.filter((item) => item.code === language);

            if (sellng.length === 1) {
                selectedLanguage = { label: sellng[0].name, value: sellng[0].code };
            }
        }

        if (
            data !== null &&
            data.occupations !== null &&
            Array.isArray(data.occupations) &&
            data.occupations.length > 0
        ) {
            var seloccup = data.occupations.filter(
                (item) => item.keyName.toLowerCase() === occupation?.toLowerCase()
            );

            if (seloccup.length === 1) {
                selectedOccupation = {
                    label: self.getName(seloccup[0]),
                    label_en: seloccup[0].nameEN,
                    value: seloccup[0].keyName,
                };
            }
        }

        if (
            data !== null &&
            data.crops !== null &&
            Array.isArray(data.crops) &&
            data.crops.length > 0
        ) {
            var selcrop = data.crops.filter((item) => item?.id === crop);

            if (selcrop.length === 1) {
                selectedCrop = {
                    value: selcrop[0].id,
                    label: self.getName(selcrop[0]),
                    label_en: selcrop[0].nameEN,
                };
            }
        }

        if (
            data !== null &&
            data.pests !== null &&
            Array.isArray(data.pests) &&
            data.pests.length > 0
        ) {
            var selpest = data.pests.filter((item) => item?.id === pest);

            if (selpest.length === 1) {
                selectedPest = {
                    value: selpest[0].id,
                    label: self.getName(selpest[0]),
                    label_en: selpest[0].nameEN,
                };
            }
        }

        return {
            ...appState,
            result: result,
            crops: data.crops.filter(s => s != null).map(function (item) {
                return {
                    value: item.id,
                    label: self.getName(item),
                    label_en: item.nameEN,
                };
            }),
            pests: data.pests.filter(s => s != null).map(function (item) {
                return {
                    value: item.id,
                    label: self.getName(item),
                    label_en: item.nameEN,
                };
            }),
            languages: data.languages.map(function (item) {
                return {
                    label: t("Language_" + item.code.toUpperCase()),
                    value: item.code,
                    nameEn: item.name,
                };
            }),
            occupations: data.occupations.map(function (item) {
                return {
                    label: self.getName(item),
                    value: item.keyName,
                    label_en: item.nameEN,
                };
            }),
            language: {
                label: t("Language_" + selectedLanguage?.value?.toUpperCase()),
                value: selectedLanguage?.value,
            },

            currentPage: Number(currentPage),

            occupation: selectedOccupation,
            crop: selectedCrop,
            pest: selectedPest,

            disabledCrop: false,
            disabledPest: false,
            disabledOccupation: false,

            countryName: data.countryName,
            countries: data.countries
                .slice()
                .map((s) => ({
                    value: s.code,
                    label: t("CountryName_" + s.code.toUpperCase()),
                    lang: s.language,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),

            showError:
                (crop === null && pest === null) || selectedOccupation === null,

            showLng: doclng !== language,
        };
    },

    /**
     * Push search into Analytic
     * @param {string} country
     * @param {string} countryName
     * @param {string} language
     * @param {string} language_code
     * @param {string} occupation
     * @param {string} occupation_en
     * @param {string crop_id
     * @param {string} crop
     * @param {string} crop_en
     * @param {string} pest_id
     * @param {string} pest
     * @param {string} pest_en
     * @param {string} result_count
     */
    pushSearchAnalytic: (
        country,
        countryName,
        language,
        language_code,
        occupation,
        occupation_en,
        crop_id,
        crop,
        crop_en,
        pest_id,
        pest,
        pest_en,
        result_count
    ) => {

        //Add validation, if search is empty, don't push event
        if (country && occupation && (crop_id || pest_id)) {

            let searchData = {};
            searchData.country = country.toUpperCase();
            searchData.country_name = countryName;
            searchData.language = language;
            searchData.language_code = language_code;

            if (crop)
                searchData.crop = crop;

            if (crop_en)
                searchData.crop_en = crop_en;

            if (crop_id)
                searchData.crop_id = searchApplication.extractId(crop_id);

            if (pest)
                searchData.pest = pest;

            if (pest_en)
                searchData.pest_en = pest_en;

            if (pest_id)
                searchData.pest_id = searchApplication.extractId(pest_id);

            if (occupation)
                searchData.occupation = occupation;

            if (occupation_en)
                searchData.occupation_en = occupation_en;

            if (result_count)
                searchData.result_count = result_count;

            window._mtm = window._mtm || []; // Ensure _mtm exists
            window._mtm.push({
                event: "search-performed",
                searchData: searchApplication.cleanData(searchData)
            });

            searchApplication.pushGtm(country,
                countryName,
                language,
                language_code,
                occupation,
                occupation_en,
                crop_id,
                crop,
                crop_en,
                pest_id,
                pest,
                pest_en,
                result_count);
        }
    },

    pushProductAnalytic: (product, search) => {

        const get = function (obj, key) {
            let value = key.split(".").reduce(function (o, x) {
                return typeof o == "undefined" || o === null ? o : o[x];
            }, obj);

            if (value === "") {
                value = null;
            }

            return value;
        };

        const productLinkInfo = searchApplication.getProductInfoLinks(
            product.productDetail.linkInfo
        );


        let searchData = {}
        searchData.country = product.productDetail.countryCode.toUpperCase();
        searchData.country_name = product.productDetail.countryName;
        searchData.language = product.productDetail.languageName;
        searchData.language_code = product.productDetail.languageCode.toLowerCase();

        if (search?.crop)
            searchData.crop = search?.crop;

        if (search?.crop_en)
            searchData.crop_en = search?.crop_en;

        if (search?.crop_id)
            searchData.crop_id = searchApplication.extractId(search?.crop_id);

        if (search?.pest)
            searchData.pest = search?.pest;

        if (search?.pest_en)
            searchData.pest_en = search?.pest_en;

        if (search?.pest_id)
            searchData.pest_id = searchApplication.extractId(search?.pest_id);

        if (search?.occupation)
            searchData.occupation = search?.occupation;

        if (search?.occupation_en)
            searchData.occupation_en = search?.occupation_en;

        if (search?.result_count)
            searchData.result_count = search?.result_count;

        let productData = {};
        productData.product_id = product.productDetail.id;

        if (Array.isArray(product.productDetail.keyInfo.activeIngredient))
            productData.active_ingredient = product.productDetail.keyInfo.activeIngredient
                .map((x) => x.nameEN)
                .filter(function (x) {
                    if (x && x !== "") return true;
                    return false;
                })
                .join(", ");

        let category = get(product.productDetail, "keyInfo.category.nameEN");
        if (category)
            productData.category = category;

        if (Array.isArray(product.productDetail.enhancedProductInfo))
            productData.enhanced_info = product.productDetail.enhancedProductInfo
                .filter((x) => x.values)
                .map((x) => {
                    return {
                        label: x.label,
                        value: x.values
                            .map((y) => y.nameEN)
                            .filter(function (z) {
                                if (z && z !== "") return true;
                                return false;
                            })
                            .join(", "),
                    };
                });

        let manufacturer = get(product.productDetail, "keyInfo.manufacturer.nameEN");
        if (manufacturer)
            productData.manufacturer = manufacturer;

        if (product.productDetail.nameEN)
            productData.product_name = product.productDetail.nameEN;

        let registrant = get(product.productDetail, "keyInfo.registrant.nameEN");
        if (registrant)
            productData.registrant = registrant;

        let registration_number = get(product.productDetail, "keyInfo.registrationNumber.nameEN");
        if (registration_number)
            productData.registration_number = registration_number;

        if (Array.isArray(product.productDetail.keyInfo.distributors))
            productData.distributors = product.productDetail.keyInfo.distributors
                .map((x) => x.name)
                .filter(function (x) {
                    if (x && x !== "") return true;
                    return false;
                })
                .join(", ");

        productData.distributor_website = !!product.productDetail?.distributors?.some(
            (item) => item.contactDetails?.companyWebsite
        );

        productData.product_factsheet = !!productLinkInfo.productFactsheet;

        productData.safety_datasheet = !!productLinkInfo.manufacturerSafetyDatasheet;

        window._mtm = window._mtm || [];
        window._mtm.push({
            event: "product-viewed",
            searchData: searchApplication.cleanData(searchData),
            productData: searchApplication.cleanData(productData)
        });
        searchApplication.pushGtmProductDetails(product,search);
    },

    pushGtm: (
        country,
        countryName,
        language,
        language_code,
        occupation,
        occupation_en,
        crop_id,
        crop,
        crop_en,
        pest_id,
        pest,
        pest_en,
        result_count
    ) => {
        //Add validation, if search is empty, don't push event
        if (country && occupation && (crop_id || pest_id)) {

            //Push to GTM is language override
            let docLng = searchApplication.getDocLng();
            let lngOverride = docLng !== language_code;

            //GTM code
            const searchPerformedArgs = {
                dataLayer: {
                    event: "search-performed",
                    page: "Search",
                    country: country,
                    country_name: countryName,
                    language: language,
                    language_code: language_code,
                    occupation:
                        occupation === "" || occupation === null ? "No Data" : occupation,
                    occupation_en:
                        occupation_en === "" || occupation_en === null
                            ? "No Data"
                            : occupation_en,
                    crop_id: crop_id === "" || crop_id === null ? "No Data" : searchApplication.extractId(crop_id),
                    crop: crop === "" || crop === null ? "No Data" : crop,
                    crop_en: crop_en === "" || crop_en === null ? "No Data" : crop_en,
                    pest_id: pest_id === "" || pest_id === null ? "No Data" : searchApplication.extractId(pest_id),
                    pest: pest === "" || pest === null ? "No Data" : pest,
                    pest_en: pest_en === "" || pest_en === null ? "No Data" : pest_en,
                    result_count: result_count,
                    override_lng: lngOverride,
                    override_lngname: lngOverride ? docLng : "No Data",
                },
                dataLayerName: "BioprotectionPortal",
            };

            if (searchPerformedArgs.dataLayer.occupation === "No Data"
                || (searchPerformedArgs.dataLayer.pest_id === "No Data"
                    && searchPerformedArgs.dataLayer.occupation === "No Data")) {
                return;
            }

            //Add OneTrust Active Groups
            if (window.OnetrustActiveGroups) {
                searchPerformedArgs.dataLayer.onetrust_active_groups = window.OnetrustActiveGroups;
            }

            //pushed GTM datalayer
            TagManager.dataLayer(searchPerformedArgs);
        }
    },

    pushGtmProductDetails: (product, search) => {
    
        const get = function (obj, key) {
            let value = key.split(".").reduce(function (o, x) {
                return typeof o == "undefined" || o === null ? o : o[x];
            }, obj);

            if (value === "") {
                value = "No Data";
            }

            return value;
        };

        const onlyUnique = function (value, index, self) {
            return self.indexOf(value) === index;
        };

        const productLinkInfo = searchApplication.getProductInfoLinks(
            product.productDetail.linkInfo
        );

        let searchData = {};
        if(search){
            if (search?.crop)
                searchData.crop = search?.crop;
            else
                searchData.crop = "No Data";
    
            if (search?.crop_en)
                searchData.crop_en = search?.crop_en;
            else
                searchData.crop_rn = "No Data";
    
            if (search?.crop_id)
                searchData.crop_id = searchApplication.extractId(search?.crop_id);
            else
                searchData.crop_id = "No Data";
    
            if (search?.pest)
                searchData.pest = search?.pest;
            else
                searchData.pest = "No Data";
    
            if (search?.pest_en)
                searchData.pest_en = search?.pest_en;
            else
                searchData.pest_en = "No Data";
    
            if (search?.pest_id)
                searchData.pest_id = searchApplication.extractId(search?.pest_id);
            else
                searchData.pest_id = "No Data";
    
            if (search?.occupation)
                searchData.occupation = search?.occupation;
            else
                searchData.occupation = "No Data";
    
            if (search?.occupation_en)
                searchData.occupation_en = search?.occupation_en;
            else
                searchData.occupation_en = "No Data";
    
            if (search?.result_count)
                searchData.result_count = search?.result_count;
            else
                searchData.result_count = "No Data";
    
        }

        const productDetails = {
            dataLayer: {
                event: "product-viewed",
                page: "ProductDetails",
                country: product.productDetail.countryCode,
                country_name: product.productDetail.countryName,
                search_data: search ? searchData:  "No Data",
                product_id: product.productDetail.id,
                product_factsheet: !!productLinkInfo.productFactsheet,
                distributor_website: !!product.productDetail?.distributors?.some(
                    (item) => item.contactDetails?.companyWebsite
                ),
                safety_datasheet: !!productLinkInfo.manufacturerSafetyDatasheet,
                label: !!productLinkInfo.productLabel,
                local: {
                    product_name: searchApplication.getName(product.productDetail),
                    registration_number:
                        get(product.productDetail, "keyInfo.registrationNumber.name") ??
                        "No Data",
                    category:
                        get(product.productDetail, "keyInfo.category.name") ?? "No Data",
                    registrant:
                        get(product.productDetail, "keyInfo.registrant.name") ?? "No Data",
                    manufacturer:
                        get(product.productDetail, "keyInfo.manufacturer.name") ??
                        "No Data",
                },
                en: {
                    product_name: product.productDetail.nameEN,
                    registration_number:
                        get(product.productDetail, "keyInfo.registrationNumber.nameEN") ??
                        "No Data",
                    category:
                        get(product.productDetail, "keyInfo.category.nameEN") ?? "No Data",
                    registrant:
                        get(product.productDetail, "keyInfo.registrant.nameEN") ??
                        "No Data",
                    manufacturer:
                        get(product.productDetail, "keyInfo.manufacturer.nameEN") ??
                        "No Data",
                },
            },
            dataLayerName: "BioprotectionPortal",
        };

        //Insert distributors
        if (Array.isArray(product.productDetail.keyInfo.distributors)) {
            productDetails.dataLayer.distributors =
                product.productDetail.keyInfo.distributors
                    .map((x) => x.name)
                    .filter(function (x) {
                        if (x && x !== "") return true;
                        return false;
                    })
                    .join(", ");
        } else {
            productDetails.dataLayer.distributors = "No Data";
        }

        //Insert activeIngredient
        if (Array.isArray(product.productDetail.keyInfo.activeIngredient)) {
            productDetails.dataLayer.local.active_ingredient =
                product.productDetail.keyInfo.activeIngredient
                    .map((x) => x.name)
                    .filter(function (x) {
                        if (x && x !== "") return true;
                        return false;
                    })
                    .join(", ");

            productDetails.dataLayer.en.active_ingredient =
                product.productDetail.keyInfo.activeIngredient
                    .map((x) => x.nameEN)
                    .filter(function (x) {
                        if (x && x !== "") return true;
                        return false;
                    })
                    .join(", ");
        } else {
            productDetails.dataLayer.local.active_ingredient = "No Data";
            productDetails.dataLayer.en.active_ingredient = "No Data";
        }

        //Insert crop/pest
        if (Array.isArray(product.productDetail.cropPestInfo)) {
            productDetails.dataLayer.en.target_crops =
                product.productDetail.cropPestInfo
                    .map((x) => x.crop.nameEN)
                    .filter(function (x) {
                        if (x && x !== "") return true;
                        return false;
                    })
                    .filter(onlyUnique)
                    .join(", ");

            productDetails.dataLayer.local.target_crops =
                product.productDetail.cropPestInfo
                    .map((x) => x.crop.name)
                    .filter(function (x) {
                        if (x && x !== "") return true;
                        return false;
                    })
                    .filter(onlyUnique)
                    .join(", ");

            productDetails.dataLayer.en.target_pests =
                product.productDetail.cropPestInfo
                    .map((x) => x.pest.nameEN)
                    .filter(function (x) {
                        if (x && x !== "") return true;
                        return false;
                    })
                    .filter(onlyUnique)
                    .join(", ");

            productDetails.dataLayer.local.target_pests =
                product.productDetail.cropPestInfo
                    .map((x) => x.pest.name)
                    .filter(function (x) {
                        if (x && x !== "") return true;
                        return false;
                    })
                    .filter(onlyUnique)
                    .join(", ");
        } else {
            productDetails.dataLayer.en.target_crops = "No Data";
            productDetails.dataLayer.local.target_crops = "No Data";
            productDetails.dataLayer.en.target_pests = "No Data";
            productDetails.dataLayer.local.target_pests = "No Data";
        }

        //Insert enhanced information
        if (Array.isArray(product.productDetail.enhancedProductInfo)) {
            product.productDetail.enhancedProductInfo =
                product.productDetail.enhancedProductInfo.filter((x) => x.values);
            productDetails.dataLayer.local.enhanced_info =
                product.productDetail.enhancedProductInfo
                    .filter((x) => x.values)
                    .map((x) => {
                        return {
                            label: x.label,
                            value: x.values
                                .map((y) => searchApplication.getName(y))
                                .filter(function (z) {
                                    if (z && z !== "") return true;
                                    return false;
                                })
                                .join(", "),
                        };
                    });

            productDetails.dataLayer.en.enhanced_info =
                product.productDetail.enhancedProductInfo
                    .filter((x) => x.values)
                    .map((x) => {
                        return {
                            label: x.label,
                            value: x.values
                                .map((y) => y.nameEN)
                                .filter(function (z) {
                                    if (z && z !== "") return true;
                                    return false;
                                })
                                .join(", "),
                        };
                    });
        } else {
            productDetails.dataLayer.local.enhanced_info = "No Data";
            productDetails.dataLayer.en.enhanced_info = "No Data";
        }

        //Add OneTrust Active Groups
        if (window.OnetrustActiveGroups) {
            productDetails.dataLayer.onetrust_active_groups = window.OnetrustActiveGroups;
        }

        //pushed GTM datalayer
        TagManager.dataLayer(productDetails);
    },

    // Remove "\r\n" from string
    cleanData: (obj) => {
        if (Array.isArray(obj)) {
            return obj.map(searchApplication.cleanData); // Recursively clean if it's an array
        } else if (typeof obj === 'object' && obj !== null) {
            let cleanedObj = {};
            for (let key in obj) {
                if (typeof obj[key] === 'string') {
                    // Replace \r\n with empty string
                    cleanedObj[key] = obj[key].replace(/\r\n/g, '');
                } else if (typeof obj[key] === 'object') {
                    // Recursively clean nested objects
                    cleanedObj[key] = searchApplication.cleanData(obj[key]);
                } else {
                    cleanedObj[key] = obj[key]; // Preserve non-string values
                }
            }
            return cleanedObj;
        }
        return obj;
    },

    extractId: (input) => {
        const ids = input.match(/\d+(?=--)/g);
        return ids ? ids.join(',') : null;
    },

    /**
     * Method to check whether the language is RTL
     * @param {any} i18n
     */
    isRTL: (i18n) => {
        return i18n.dir() === "rtl";
    },

    /**
     * Method to remove white space in string
     * @param {String} str
     * @returns string with all white space removed
     */
    removeWhiteSpace(str) {
        if (str) return str.replace(/\s/g, "");
        return "";
    },

    /**
     * Sort list in ascending order based on a property
     * @param {string} [property]
     */
    sortOnAsc(property) {
        return function (a, b) {
            var val = (a[property] ?? "")
                .trim()
                .localeCompare((b[property] ?? "").trim());
            if (val >= 0) {
                if (val === 0) {
                    return 0;
                } else {
                    return 1;
                }
            } else {
                return -1;
            }
        };
    },

    /**
     * Sort list in descending order based on a property
     * @param {string} [property]
     */
    sortOnDesc(property) {
        return function (a, b) {
            var val = (a[property] ?? "")
                .trim()
                .localeCompare((b[property] ?? "").trim());
            if (val >= 0) {
                if (val === 0) {
                    return 0;
                } else {
                    return -1;
                }
            } else {
                return 1;
            }
        };
    },

    /**
     * Sort product by product name
     * @param {Array} [products]
     * @param {string} [order]
     */
    sortProductByName: function (products, order = null) {
        if (products) {
            var productsCopy = [...products];
            productsCopy.forEach((item) => {
                item.displayName = this.getName(item.product);
            });

            if (order === null) {
                return this.sortRandom(productsCopy);
            } else {
                return productsCopy.sort(
                    order === "ASC"
                        ? this.sortOnAsc("displayName")
                        : this.sortOnDesc("displayName")
                );
            }
        }

        return [];
    },

    /**
     * Create a list of active ingredients from search result
     * @param {Array} [result]
     */
    activeIngredients: function (result, supportedStates = []) {
        var u = {},
            a = [];

        var resultToCheck = result ?? [];
        if (resultToCheck) {
            for (var i = 0; i < resultToCheck.length; i++) {
                if (resultToCheck[i].product.activeIngredient) {
                    for (
                        var j = 0;
                        j < resultToCheck[i].product.activeIngredient.length;
                        j++
                    ) {
                        if (
                            !u.hasOwnProperty(resultToCheck[i].product.activeIngredient[j].id)
                        ) {
                            var displayName = resultToCheck[i].product.activeIngredient[j]
                                .name
                                ? resultToCheck[i].product.activeIngredient[j].name
                                : resultToCheck[i].product.activeIngredient[j].nameEN;

                            var activeIngredient = {};
                            activeIngredient["value"] =
                                resultToCheck[i].product.activeIngredient[j].id;
                            activeIngredient["label"] = displayName;
                            activeIngredient["label_en"] =
                                resultToCheck[i].product.activeIngredient[j].nameEN;

                            a.push(activeIngredient);

                            u[resultToCheck[i].product.activeIngredient[j].id] = 1;
                        }
                    }
                }
            }
        }
        return a.sort(this.sortOnAsc("label"));
    },

    /**
     * Create a list of supported states from search result
     * @param {Array} [result]
     */
    supportedStates: function (result, activeIngredient = "") {
        var tempMap = {},
            tempSupportedStates = [];

        var resultToCheck = result;

        if (resultToCheck) {
            for (var i = 0; i < resultToCheck.length; i++) {
                if (resultToCheck[i].supportedStates) {
                    for (var j = 0; j < resultToCheck[i].supportedStates.length; j++) {
                        if (
                            !tempMap.hasOwnProperty(
                                resultToCheck[i].supportedStates[j].stateCode
                            )
                        ) {
                            var state = {};
                            state.value = resultToCheck[i].supportedStates[j].stateCode;
                            state.label = resultToCheck[i].supportedStates[j].stateName;

                            tempSupportedStates.push(state);
                            tempMap[resultToCheck[i].supportedStates[j].stateCode] = 1;
                        }
                    }
                }
            }
        }

        return tempSupportedStates.sort(this.sortOnAsc("label"));
    },

    /**
     * Push english language at end of list
     * @param {Array} [languages]
     */
    englishLanguageAtEnd: function (languages) {
        if (languages && languages.length > 1) {
            let clonedLanguages = [...languages];

            let indexEnglish = clonedLanguages.findIndex(
                (s) => s.value.toLowerCase() === "en"
            );

            let english = clonedLanguages.find((s) => s.value.toLowerCase() === "en");

            if (indexEnglish > -1) {
                clonedLanguages.splice(indexEnglish, 1);
                clonedLanguages.push(english);
            }

            return clonedLanguages;
        }

        return languages;
    },

    /**
     * Filtered the result based on the active ingredient
     * @param {Array} [result]
     * @param {number} [id]
     */
    filteredByActiveIngredient: function (result, id) {
        var a = [];
        if (result && result.length > 0) {
            for (var i = 0; i < result.length; i++) {
                if (result[i].product.activeIngredient) {
                    var filteredResult = result[i].product.activeIngredient.filter(
                        (item) => {
                            return item.id === id;
                        }
                    );

                    if (filteredResult.length > 0) {
                        a.push(result[i]);
                    }
                }
            }
        }
        return a;
    },

    /**
     * Filtered the result based on the active ingredient
     * @param {Array} [result]
     * @param {Array} [supportedStates]
     */
    filteredByState: function (result, supportedStates) {
        var a = [];
        var map = {};
        if (result && result.length > 0) {
            for (let i = 0; i < result.length; i++) {
                supportedStates.forEach(function (state) {
                    if (result[i].supportedStates) {
                        var filteredResult = result[i].supportedStates.filter((item) => {
                            return item.stateCode === state.value;
                        });

                        if (filteredResult.length > 0 && !map[result[i].product.id]) {
                            a.push(result[i]);
                            map[result[i].product.id] = 1;
                        }
                    }
                });
            }
        }
        return a;
    },

    /**
     * Create model for tree view of crop and pest
     * @param {any} list
     */
    getCropPestTree: function (list) {
        var croplist = [];
        var pestlist = [];

        //Get the term type narrower or broader
        const getTermType = (term) => {
            if (
                term.narrowTerm &&
                term.broaderTerm &&
                Array.isArray(term.narrowTerm) &&
                Array.isArray(term.broaderTerm)
            ) {
                //if term exist in the narrower term list then it's a narrower term
                let filterInNarrower = term.narrowTerm.filter((s) => {
                    return s.itemId === term.id;
                });

                if (filterInNarrower.length > 0) {
                    return "N";
                } else {
                    return "B";
                }
            }
            return "T";
        };

        //create terms based on Broader, Narrower and Term
        const createTerms = (idSuffix, term, list) => {
            if (term.type === "N") {
                let nodes = [];

                term.broaderTerm.forEach((bterm) => {
                    let node = null;

                    let filtered = list.filter((i) => {
                        return `${i.id}` === `${bterm.itemId}${idSuffix}`;
                    });

                    if (filtered.length > 0) {
                        //if exist assign it to node
                        node = filtered[0];
                    } else {
                        //if not exists create a new node
                        node = {
                            displayName: this.getName(bterm),
                            id: `${bterm.itemId}${idSuffix}`,
                            name: bterm.name,
                            nameEN: bterm.label,
                            type: "N",
                            child: [],
                            alternateTerm:
                                term.alternateTerm?.map((s) => {
                                    return {
                                        id: s.itemId,
                                        name: s.name,
                                        nameEN: s.label,
                                        displayName: this.getName({
                                            name: s.name,
                                            nameEN: s.label,
                                        }),
                                    };
                                }) ?? [],
                            narrowTerm: [
                                {
                                    id: term.id,
                                    name: term.name,
                                    nameEN: term.nameEN,
                                    displayName: this.getName({
                                        name: term.name,
                                        nameEN: term.nameEN,
                                    }),
                                },
                            ],
                        };

                        list.push(node);
                        nodes.push(node);
                    }
                });

                return nodes;
            } else {
                let node = null;

                let filtered = list.filter((i) => {
                    return `${i.id}` === `${term.id}${idSuffix}`;
                });

                if (filtered.length > 0) {
                    //if exist assign it to node
                    node = filtered[0];
                } else {
                    //if not exists create a new node
                    node = {
                        displayName: this.getName(term),
                        id: `${term.id}${idSuffix}`,
                        name: term.name,
                        nameEN: term.nameEN,
                        type: term.type,
                        child: [],
                        alternateTerm:
                            term.alternateTerm?.map((s) => {
                                return {
                                    id: s.itemId,
                                    name: s.name,
                                    nameEN: s.label,
                                    displayName: this.getName({
                                        name: s.name,
                                        nameEN: s.label,
                                    }),
                                };
                            }) ?? [],
                        narrowTerm:
                            term.narrowTerm?.map((s) => {
                                return {
                                    id: s.itemId,
                                    name: s.name,
                                    nameEN: s.label,
                                    displayName: this.getName({
                                        name: s.name,
                                        nameEN: s.label,
                                    }),
                                };
                            }) ?? [],
                    };

                    list.push(node);
                }
                return [node];
            }
        };

        if (list && list.length > 0) {
            list.forEach((item) => {
                if (item.crop && item.pest) {
                    item.crop.type = getTermType(item.crop);
                    item.pest.type = getTermType(item.pest);
                }
            });

            list.forEach((item) => {
                if (item.crop && item.pest) {
                    let cropNodes = createTerms("C", item.crop, croplist);
                    cropNodes.forEach((node) => {
                        createTerms(`P${node.id}`, item.pest, node.child);
                    });

                    let pestNodes = createTerms("P", item.pest, pestlist);
                    pestNodes.forEach((node) => {
                        createTerms(`C${node.id}`, item.crop, node.child);
                    });
                }
            });
        }

        //Before sorting, check for ALL Crop, if it's exist then this must at the top
        let cropAll = croplist.find((x) => {
            return x.displayName.toLowerCase() === allCropConstant.name;
        });

        //if all crop exists
        if (cropAll) {
            //remove this from list before sorting
            croplist = croplist.filter((x) => {
                return x.displayName.toLowerCase() !== allCropConstant.name;
            });
        }

        //Sort the list
        croplist = croplist.sort(this.sortOnAsc("displayName"));

        //if all crop exists add this at first position
        if (cropAll) {
            croplist = [cropAll, ...croplist];
        }

        //sort children
        croplist.forEach((item) => {
            item.child = item.child.sort(this.sortOnAsc("displayName"));
        });

        pestlist = pestlist.sort(this.sortOnAsc("displayName"));
        pestlist.forEach((item) => {
            let cropAll = item.child.find((x) => {
                return x.displayName.toLowerCase() === allCropConstant.name;
            });

            //if all crop exists
            if (cropAll) {
                //remove this from list before sorting
                item.child = item.child.filter((x) => {
                    return x.displayName.toLowerCase() !== allCropConstant.name;
                });
            }

            item.child = item.child.sort(this.sortOnAsc("displayName"));

            //if all crop exists add this at first position
            if (cropAll) {
                item.child = [cropAll, ...item.child];
            }
        });

        return { croplist, pestlist };
    },

    /**
     * Create the product infor links from array of values
     * @param {any} linkInfo
     */
    getProductInfoLinks: (linkInfo) => {
        let productLinkInfo = {
            productLabel: null,
            productLabelEN: null,
            manufacturerSafetyDatasheet: null,
            manufacturerSafetyDatasheetEN: null,
            productFactsheet: null,
            productFactsheetEN: null,
        };

        if (Array.isArray(linkInfo)) {
            linkInfo.forEach((item) => {
                if (item.name === productInfoLinksName.productLabel) {
                    productLinkInfo.productLabel = item.value === "" ? null : item.value;
                } else if (item.name === productInfoLinksName.productLabelEN) {
                    productLinkInfo.productLabelEN =
                        item.value === "" ? null : item.value;
                } else if (
                    item.name === productInfoLinksName.manufacturerSafetyDatasheet
                ) {
                    productLinkInfo.manufacturerSafetyDatasheet =
                        item.value === "" ? null : item.value;
                } else if (
                    item.name === productInfoLinksName.manufacturerSafetyDatasheetEN
                ) {
                    productLinkInfo.manufacturerSafetyDatasheetEN =
                        item.value === "" ? null : item.value;
                } else if (item.name === productInfoLinksName.productFactsheet) {
                    productLinkInfo.productFactsheet =
                        item.value === "" ? null : item.value;
                } else if (item.name === productInfoLinksName.productFactsheetEN) {
                    productLinkInfo.productFactsheetEN =
                        item.value === "" ? null : item.value;
                }
            });
        }

        return {
            productLabel:
                productLinkInfo.productLabel ?? productLinkInfo.productLabelEN,
            manufacturerSafetyDatasheet:
                productLinkInfo.manufacturerSafetyDatasheet ??
                productLinkInfo.manufacturerSafetyDatasheetEN,
            productFactsheet:
                productLinkInfo.productFactsheet ?? productLinkInfo.productFactsheetEN,
        };
    },

    /**
     * Function to create enhanced information model for rendering
     * @param {Array} list
     * @returns list
     */
    getEnchancedInformation: function (list) {
        let enhancedInfo = [];

        if (Array.isArray(list) && list.length > 0) {
            list.forEach((item) => {
                if (Array.isArray(item.values) && item.values.length > 0) {
                    item.labelTrans = this.replaceSpecialCharacter(item.label);

                    item.values.forEach((subitem) => {
                        subitem.displayName = this.getName(subitem);
                    });

                    item.values = item.values.sort(this.sortOnAsc("displayName"));

                    enhancedInfo.push(item);
                }
            });
        }

        return enhancedInfo.sort(this.sortOnAsc("label"));
    },

    /**
     *  Create model for tree view of crop and pest
     *  * @param {any} list
     **/
    getCropPestTreeAsync: function (list) {
        var $this = this;
        list.forEach(function (item) {
            item.parent.displayName = $this.getName(item.parent);
            item.child.forEach(function (subitem) {
                subitem.displayName = $this.getName(subitem);
                if (subitem.alternateTerm) {
                    subitem.alternateTerm.forEach(function (subsubitem) {
                        subsubitem.displayName = $this.getName({
                            id: subsubitem.itemId,
                            name: subsubitem.name,
                            nameEN: subsubitem.label,
                        });
                    });
                }
            });
        });
        return list;
    },

    /**
     * Sort list in occupation ascending order based on a property
     * @param {string} [property]
     * @param {string} [propertyEn]
     */
    sortOnAscOccuption(property, propertyEn) {
        return function (a, b) {
            if (a[propertyEn] === "Other" || b[propertyEn] === "Other") {
                return -1;
            } else {
                var val = (a[property] ?? "")
                    .trim()
                    .localeCompare((b[property] ?? "").trim());

                if (val >= 0) {
                    if (val === 0) {
                        return 0;
                    } else {
                        return 1;
                    }
                } else {
                    return -1;
                }
            }
        };
    },

    /**
     * Function to create occupation model for rendering
     * @param occupations
     * @returns occupations
     */
    getOccupation: function (occupations) {
        let sortedList = [];
        let withoutotherOccupation = occupations.filter(function (index) {
            return index.label_en !== "Other";
        });
        withoutotherOccupation.forEach(function (item) {
            let displayName = item.label;
            if (
                item.label === "" ||
                item.label === null ||
                item.label === undefined
            ) {
                displayName = item.label_en;
            }
            sortedList.push({
                displayName: displayName,
                value: item.value,
                label: item.label,
                label_en: item.label_en,
            });
        });

        sortedList = sortedList.sort(searchApplication.sortOnAsc("label"));

        let otherOccupation = occupations.filter(function (index) {
            return index.label_en === "Other";
        });
        otherOccupation.forEach(function (item) {
            let displayName = item.label;
            if (
                item.label === "" ||
                item.label === null ||
                item.label === undefined
            ) {
                displayName = item.label_en;
            }
            sortedList.push({
                displayName: displayName,
                value: item.value,
                label: item.label,
                label_en: item.label_en,
            });
        });

        return sortedList;
    },
};

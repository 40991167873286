import * as React from "react"

function IconShield(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={25}
            height={22}
            fill="none"
            {...props}
        >
            <path
                d="M1.494 6.389C2.37 2.17 7.563-1 12.5 4.606c7.046-8.075 14.571 1.937 9.463 7.097L12.5 20.429l-5.537-5.143"
                stroke="#333"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.357 11h4.286l2.571-3.429 3.429 6 2.571-3.428h2.572"
                stroke="#333"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default IconShield

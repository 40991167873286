import React, { useState } from "react";
import { SettingsContext } from "../settingsContext";
import { withTranslation, Trans } from "react-i18next";

import { IconPlayStore } from "../icons";

function CropSprayerApp(props) {
  const { nameEN } = props;
  const settings = React.useContext(SettingsContext);

  const showCropSprayer = (nameEN) => {

    if(settings.standalone) return false;
    
    if (Array.isArray(settings?.cropsprayer?.categories)) {
      let cat = settings.cropsprayer.categories.find(function (item) {
        return item.toLowerCase() === nameEN.toLowerCase();
      });

      if (cat) return true;
    }

    return false;
  };

  const [appState] = useState({ show: showCropSprayer(nameEN) });

  return (
    appState.show && (
      <div className="row" data-category={nameEN}>
        <div className={settings.is_mobile ? "col-12" : "col-12 py-5"}>
          <div className="row">
            {!settings.is_mobile && settings.cropsprayer.img && (
              <div className="col-2">
                <img className="w-100" alt="" src={settings.cropsprayer.img} />
              </div>
            )}
            <div className={settings.is_mobile ? "col-12" : "col-10"}>
              <h3 className="py-3">
                <Trans>CropSprayerTitle</Trans>
              </h3>
              <p>
                <Trans>CropSprayerDescription</Trans>
              </p>
              <ul>
                <li>
                  <Trans>CropSprayerBullet1</Trans>
                </li>
                <li>
                  <Trans>CropSprayerBullet2</Trans>
                </li>
                <li>
                  <Trans>CropSprayerBullet3</Trans>
                </li>
                <li>
                  <Trans>CropSprayerBullet4</Trans>
                </li>
              </ul>
              <p><Trans>CropSprayeriOS</Trans></p>
              <p>
                {settings.cropsprayer.learnmore && (
                  <a
                    className="btn p-info-btn me-3"
                    href={settings.cropsprayer.learnmore}
                  >
                    <Trans>CropSprayerLearnMore</Trans>
                  </a>
                )}
                {settings.cropsprayer.playstore && (
                  <a target="_blank" href={settings.cropsprayer.playstore}  rel="noreferrer">
                    <IconPlayStore width={150} />
                  </a>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="line-break"></div>
        </div>
      </div>
    )
  );
}

export default withTranslation()(CropSprayerApp);

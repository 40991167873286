import * as React from "react";

function IconClose(props) {
    return (
        <svg
            width={23}
            height={23}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M2 2l19 19M2 21L21 2"
                stroke="#fff"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default IconClose;

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-xhr-backend";

i18n
.use(initReactI18next)
.use(HttpApi)
.use(LanguageDetector);

export default i18n;

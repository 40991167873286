import React from "react";
import { SettingsContext } from "../settingsContext";

export default function ErrorUI() {
    const settings = React.useContext(SettingsContext);

    return settings.standalone ? null : (
        <div className="container py-5 my-5">
            <h1 className="display-5 text-center">
                Sorry, something went wrong. Try reloading the page.
            </h1>
        </div>
    );
}
import * as React from "react";

function IconStar(props) {
  return (
    <svg
      width={12}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.077.511a1.09 1.09 0 0 1 1.846 0l1.79 2.834 2.335.286a1.07 1.07 0 0 1 .693 1.763L9.793 7.627l.628 3.092a1.08 1.08 0 0 1-.425 1.08 1.098 1.098 0 0 1-1.163.065L6 10.314l-2.833 1.55a1.099 1.099 0 0 1-1.163-.065 1.08 1.08 0 0 1-.425-1.08l.628-3.092L.26 5.394A1.07 1.07 0 0 1 .952 3.63l2.335-.286L5.077.51Z"
        fill="#D8EED4"
      />
    </svg>
  );
}

export default IconStar;

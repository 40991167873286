import * as React from "react"

const IconShieldCheck = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={42}
    fill="none"
    {...props}
  >
    <path
      d="M14.012 4.174A161.28 161.28 0 0 0 6.565 6.42a1.263 1.263 0 0 0-.86 1.023C4.25 18.358 7.61 26.319 11.617 31.563a28.153 28.153 0 0 0 6.004 5.862c.908.64 1.711 1.102 2.344 1.399.315.15.572.25.769.31.086.03.175.052.265.065.09-.014.177-.036.262-.066.2-.06.457-.16.772-.31.63-.296 1.436-.76 2.344-1.398a28.156 28.156 0 0 0 6.004-5.862c4.008-5.242 7.368-13.206 5.914-24.118a1.26 1.26 0 0 0-.861-1.024c-1.709-.56-4.594-1.47-7.447-2.245-2.914-.79-5.594-1.375-6.988-1.375-1.391 0-4.074.585-6.988 1.375v-.002Zm-.698-2.704C16.162.696 19.189 0 21 0s4.838.696 7.686 1.47c2.914.787 5.851 1.72 7.578 2.284a4.043 4.043 0 0 1 2.74 3.312c1.565 11.753-2.065 20.462-6.47 26.224a30.91 30.91 0 0 1-6.607 6.44 18.793 18.793 0 0 1-2.75 1.64c-.736.347-1.526.63-2.177.63s-1.439-.283-2.176-.63a18.79 18.79 0 0 1-2.751-1.64 30.914 30.914 0 0 1-6.607-6.44C5.06 27.528 1.43 18.82 2.996 7.066a4.042 4.042 0 0 1 2.74-3.312 163.952 163.952 0 0 1 7.578-2.284Z"
      fill="#165158"
    />
    <path
      d="M28.492 13.508a1.311 1.311 0 0 1 0 1.859l-7.875 7.875a1.312 1.312 0 0 1-1.859 0l-3.937-3.938a1.313 1.313 0 0 1 .929-2.243 1.315 1.315 0 0 1 .93.385l3.008 3.01 6.945-6.948a1.311 1.311 0 0 1 1.859 0Z"
      fill="#165158"
    />
  </svg>
)

export default IconShieldCheck
